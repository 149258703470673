import styled from "styled-components"

export const ProductCardStyles = styled.div`
  #card-container {
    width: 350px;
    height: 460px;
    border: 1px solid #ccd2c6;
    margin-bottom: 10px;
    margin-top: 10px;

    #image-container {
      /* width: 350px; */
      height: 210px;
      background-color: #e5e5e5;
      padding: 1rem;

      img {
        margin-left: 3rem;
        height: 100%;
        object-fit: contain;
      }
    }

    #product-heading {
      background-color: #606b55;
      display: grid;
      grid-template-columns: 25% 75%;

      h2 {
        width: 300px;
        margin: 0;
        padding: 10px;
      }

      p {
        justify-self: right;
        padding-right: 20px;
        color: white;
        font-style: italic;
      }
    }

    .product-info {
      /* text-align: center; */
      ul {
        list-style-type: none;

        li {
          margin: 0.3rem 0;
        }
      }
    }

    #btn-container {
      /* background-color: orange; */
      button {
        padding: 10px;
        width: 150px;
        margin-left: 100px;
        margin-right: auto;
        /* text-align: center; */
        border: none;
        background-color: #333;
        color: white;

        &:hover {
          background-color: #f26522;
          color: #333;
          cursor: pointer;
          transition-duration: 0.5s;
        }
      }
    }
  }
`
