import React from "react"
import DemoImage from "../images/kodiak-empty-racking-blackwhite-bg.jpg"
import styled from "styled-components"
import { Link } from "gatsby"

const DemoSectionStyles = styled.div`
  #demo-section-container {
    position: relative;
    width: 100%;
    height: 500px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(80%);
    }
  }

  #overlay-content {
    position: absolute;
    top: 20%;
    color: white;
    font-size: 2vw;
    text-align: center;
    right: 25%;
    z-index: 5;
    @media (max-width: 380px) {
      font-size: 5vw;
      right: 25%;
      left: 25%;
    }
    h1 {
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: black;
    }

    button {
      background-color: #f26522;
      border: none;
      padding: 10px;
      width: 200px;
      color: white;

      &:hover {
        cursor: pointer;
      }
    }
  }
`

const DemoSection = () => {
  return (
    <DemoSectionStyles>
      <div id="demo-section-container">
        <img src={DemoImage} alt="" />
        <div id="overlay-content">
          <h1>Ready to setup a demo?</h1>
          <Link to="/demo">
            <button type="button">Setup Demo</button>
          </Link>
        </div>
      </div>
    </DemoSectionStyles>
  )
}

export default DemoSection
