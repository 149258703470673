import React from "react"
import { ProductCardStyles } from "./styles/ProductCardStyles"
import { Link } from "gatsby"

const ProductCard = props => {
  return (
    <ProductCardStyles>
      <Link style={{ color: "black", textDecoration: "none" }} to={props.link}>
        <div id="card-container">
          <div id="image-container">
            <img loading="lazy" src={props.image} alt="" />
          </div>
          <div id="product-heading">
            <h2 style={{ fontSize: props.fontSize }}>{props.heading}</h2>
            {/* <p>{props.size}</p> */}
          </div>
          {/* <div id="tank-info">
          <p>Tanks</p>
          <p>11g / 12g</p>
        </div> */}
          <div className="product-info">
            <ul>
              <li>
                <span style={{ fontWeight: "bold" }}>{props.title1}</span>{" "}
                {props.tank}
              </li>
              <hr />
              <li>
                <span style={{ fontWeight: "bold" }}>{props.title2}</span>{" "}
                {props.size}
              </li>
              <hr />
              <li>
                <span style={{ fontWeight: "bold" }}>{props.title3}</span>{" "}
                {props.time}
              </li>
            </ul>

            <div id="btn-container">
              <button className="learn-more-btn" type="button">
                Learn More
              </button>
            </div>
          </div>
        </div>
      </Link>
    </ProductCardStyles>
  )
}

export default ProductCard
