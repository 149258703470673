import styled from "styled-components"

export const ProductsPageStyled = styled.div`
  #product-card-container {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    justify-items: center;
    -ms-flex-item-align: center;
    margin: 50px;

    @media (max-width: 1230px) {
      grid-template-columns: 50% 50%;
    }
    @media (max-width: 860px) {
      grid-template-columns: 100%;
    }

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: 1400px;
      margin-left: auto;
      margin-right: auto;
    }

    @supports (-ms-ime-align: auto) {
      width: 1400px;
      margin-left: auto;
      margin-right: auto;
    }

    .card {
      position: relative;
      margin: 0 4rem;
      #summer {
        position: absolute;
        top: 30px;
        right: -30px;
        background-color: #f26522;
        color: white;
        padding: 1rem;
        transform: rotate(30deg);
      }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: -ms-flexbox;
        margin: 0 50px;
      }

      @supports (-ms-ime-align: auto) {
        display: -ms-flexbox;
        margin: 0 50px;
      }
    }
  }
`
