import React from "react"
import MainLayout from "../components/MainLayout"
import ProductCard from "../components/ProductCard"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

import { ProductsPageStyled } from "../components/styles/ProductsPageStyled"
import PageHeading from "../components/PageHeadings"
import DemoSection from "../components/DemoSection"

import K12Image from "../images/k-12-image.jpg"
import K16Image from "../images/k-16-image.jpg"
import K19Image from "../images/k19-beige.jpg"
import K25Image from "../images/k-25-image.jpg"
import K42Image from "../images/k42-main-beige.jpg"

import Orbitz from "../images/orbitz.png"

const ProductPage = () => {
  return (
    <>
      <SEO title="Floor Scrubber Lineup" />
      <Helmet>
        {/* <title>Kodiak Cleaning Equipment</title> */}
        <description>
          Our floor equipment won't break the bank and will keep scrubbing for
          years. Start scrubbing today!
        </description>
      </Helmet>
      <MainLayout>
        <ProductsPageStyled>
          <div>
            <PageHeading
              pageHeading="Walk-Behind Floor Scrubbers"
              // description="Our floor equipment won't break the bank and will keep scrubbing for years.  Start scrubbing today!"
            />

            <div id="product-card-container">
              <div className="card">
                <ProductCard
                  id="card"
                  image={Orbitz}
                  heading="Floor Machines"
                  tank='20" Cleaning Width '
                  time=" 50ft / 14 gauge"
                  size="144 - 232 lbs"
                  link="/floor-series-page"
                  title1="Cleaning Path: "
                  title2="Weight: "
                  title3="Cord: "
                />
              </div>
              {/* <div className="card">
                <ProductCard
                  id="card"
                  image={K10PA17Image}
                  heading="K10 Compact"
                  tank="Sol: 10gal (37L) Rec: 10gal (37L)"
                  time=" Up to 3 hours"
                  size="17 - 20in (43 - 51cm)"
                  link="/k10-compact"
                  title1="Tank: "
                  title2="Disk Size: "
                  title3="Run Time: "
                />
              </div> */}
              {/* <div className="card">
                <ProductCard
                  id="card"
                  image={K10StandardImage}
                  heading="K10 Standard"
                  tank="Sol: 10gal (37L) Rec: 10gal (37L)"
                  time=" Up to 3 hours"
                  size="17 - 20in (43 - 51cm)"
                  link="/k10-standard"
                  title1="Tank: "
                  title2="Disk Size: "
                  title3="Run Time: "
                />
              </div> */}
              <div className="card">
                {/* <div id="summer">Coming Fall 2020</div> */}
                <ProductCard
                  image={K12Image}
                  heading="K12 Floor Scrubber"
                  tank="Sol: 12gal (45.4L) Rec:15gal (56.8L)"
                  time=" Up to 2.5 hours"
                  size="17 - 20in (43 - 51cm)"
                  link="/k12-series"
                  title1="Tank: "
                  title2="Disk Size: "
                  title3="Run Time: "
                />
              </div>
              <div className="card">
                <ProductCard
                  id="card"
                  image={K16Image}
                  heading="K16 Floor Scrubber"
                  tank="Sol: 16gal (60L) Rec: 16gal (60L) "
                  time=" Up to 3 hours"
                  size="20 - 28in (51 - 71cm)"
                  link="/k16-series"
                  title1="Tank: "
                  title2="Disk Size: "
                  title3="Run Time: "
                />
              </div>
              <div className="card">
                {/* <div id="summer">Coming Fall 2020</div> */}
                <ProductCard
                  image={K19Image}
                  heading="K19 Floor Scrubber"
                  tank="Sol: 19gal (72L) Rec:15gal (72L)"
                  time=" Up to 2.5 hours"
                  size="24 - 28in (61 - 71cm)"
                  link="/k19-series"
                  title1="Tank: "
                  title2="Disk Size: "
                  title3="Run Time: "
                />
              </div>
              <div className="card">
                <ProductCard
                  id="card"
                  image={K25Image}
                  heading="K25 Floor Scrubber"
                  tank="Sol: 25gal (94L) Rec: 26gal (98L)"
                  time=" Up to 3.5 hours"
                  size="28 - 32in (71 - 81cm)"
                  link="/k25-series"
                  title1="Tank: "
                  title2="Disk Size: "
                  title3="Run Time: "
                />
              </div>
              {/* <div className="card">
                <ProductCard
                  id="card"
                  image={K42Image}
                  heading="Rider Floor Scrubber"
                  tank="Sol: 42gal (158L) Rec: 45gal (170L)"
                  time=" Up to 5 hours"
                  size="28 - 32in (71 - 81cm)"
                  link="/rider-series"
                  title1="Tank: "
                  title2="Disk Size: "
                  title3="Run Time: "
                />
              </div> */}
            </div>
          </div>
        </ProductsPageStyled>
        <DemoSection />
      </MainLayout>
    </>
  )
}
export default ProductPage
